import { merge } from 'theme-ui';
import { tailwind } from '@theme-ui/presets';
// eslint-disable-next-line
// @ts-ignore
import originalTheme from '@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui/index';

const preventStyle = {
  main: '#56bfc2',
  accent: { 1: '#ed8e8d', 2: '#f0c17b' },
  additional: '#a2d7da',
  background: '#d3efef',
  mainText: '#393a3a',
  accentText: {
    1: '#ec8584',
    2: '#f6b764',
  },
  additionalText: '#91959a',
};

const theme = merge(originalTheme, {
  colors: {
    primary: preventStyle.main,
    secondary: preventStyle.additionalText,
    toggleIcon: tailwind.colors.gray[8],
    heading: preventStyle.mainText,
    divide: tailwind.colors.gray[4],
    modes: {
      dark: {
        text: tailwind.colors.white,
        primary: preventStyle.accentText[2],
        secondary: tailwind.colors.gray[1],
        toggleIcon: tailwind.colors.white,
        background: preventStyle.main,
        heading: tailwind.colors.white,
        divide: tailwind.colors.gray[1],
        muted: tailwind.colors.gray[1],
      },
    },
  },
  fonts: {
    body: `"Noto Sans JP", sans-serif`,
  },
});

export default theme;
