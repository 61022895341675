import type { ThemeUICSSObject } from 'theme-ui';

export const visuallyHidden: ThemeUICSSObject = {
  // include `px` so we can use it with `sx`
  border: 0,
  clip: `rect(0, 0, 0, 0)`,
  height: `1px`,
  margin: `-1px`,
  overflow: `hidden`,
  padding: 0,
  position: `absolute`,
  whiteSpace: `nowrap`,
  width: `1px`,
};

export const contents = {
  '@media screen and (min-width: 1024px)': {
    h1: {
      fontSize: '2.75rem',
    },
    h2: {
      fontSize: '2.25rem',
    },
    h3: {
      fontSize: '1.875rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
  },
};
